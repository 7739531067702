// We make use of this 'server' variable to provide the address of the
// REST Janus API. By default, in this example we assume that Janus is
// co-located with the web server hosting the HTML pages but listening
// on a different port (8088, the default for HTTP in Janus), which is
// why we make use of the 'window.location.hostname' base address. Since
// Janus can also do HTTPS, and considering we don't really want to make
// use of HTTP for Janus if your demos are served on HTTPS, we also rely
// on the 'window.location.protocol' prefix to build the variable, in
// particular to also change the port used to contact Janus (8088 for
// HTTP and 8089 for HTTPS, if enabled).
// In case you place Janus behind an Apache frontend (as we did on the
// online demos at http://janus.conf.meetecho.com) you can just use a
// relative path for the variable, e.g.:
//
// 		var server = "/janus";
//
// which will take care of this on its own.
//
//
// If you want to use the WebSockets frontend to Janus, instead, you'll
// have to pass a different kind of address, e.g.:
//
// 		var server = "ws://" + window.location.hostname + ":8188";
//
// Of course this assumes that support for WebSockets has been built in
// when compiling the server. WebSockets support has not been tested
// as much as the REST API, so handle with care!
//
//
// If you have multiple options available, and want to let the library
// autodetect the best way to contact your server (or pool of servers),
// you can also pass an array of servers, e.g., to provide alternative
// means of access (e.g., try WebSockets first and, if that fails, fall
// back to plain HTTP) or just have failover servers:
//
//		var server = [
//			"ws://" + window.location.hostname + ":8188",
//			"/janus"
//		];
//
// This will tell the library to try connecting to each of the servers
// in the presented order. The first working server will be used for
// the whole session.
//
// var server = "/janus"; // for ec2 server
// var server = 'https://ec2-18-136-242-141.ap-southeast-1.compute.amazonaws.com/janus'; // localhost
// var server = 'https://ec2-3-0-253-77.ap-southeast-1.compute.amazonaws.com/janus';
var server = 'https://10.12.0.105/janus';
// var server = 'https://ngbms-sg.com:8080/voip/janus/';


var janus = null;
var sipcall = null;
var opaqueId = 'siptest-' + Janus.randomString(12);

var spinner = null;

var selectedApproach = null;
var registered = false;

var incoming = null;

//var host = "@192.168.1.133:5160";
var host = '@10.12.0.101';

//var validated = false;
var loginUser = null;
var sipIdentity,
	mySipIdentity,
	peerSipIdentity = null;
var myDialNumber = null;
var callEvent = '';
var jsepAnswer;

moment.updateLocale('en', {
	relativeTime : {
			future: "in %s",
			past:   "%s ago",
			s  : 'a few seconds',
			ss : '%d seconds',
			m:  "a minute",
			mm: "%d minutes",
			h:  "an hour",
			hh: "%d hours",
			d:  "a day",
			dd: "%d days",
			M:  "a month",
			MM: "%d months",
			y:  "a year",
			yy: "%d years"
	}
});

const ringtone = new Audio();
function playRingtone(isIncomingCall) {
		if (isIncomingCall) {
			ringtone.src = "assets/sounds/incoming.mp3";
			ringtone.loop = true;
			ringtone.play();
			return;
		} 
		ringtone.src = "assets/sounds/outgoing.mp3";
		ringtone.loop = true;
		ringtone.play();
}
function stopRingtone() {
		ringtone.pause();
		ringtone.currentTime = 0;
}

function InitDemo(sipData) {
	var eleStatus = $('.call-status');
	var eleIncoming = $('.incoming-call');
	var eleOutgoing = $('.outgoing-call');
	var eleIdle = $('.idle-call');
	var eleVoipHeader = $('.voip-bus-code');
	var eleEventListner = $('#event-call-listener');
	var voipPanel = $('.voip-panel-group');

	// var eleTimeTableStatus = $('.time-table-call-status');
	// var eleTimeTableIncoming = $('.time-table-incoming-call');
	// var eleTimeTableOutgoing = $('.time-table-outgoing-call');
	// var eleTimeTableIdle = $('.time-table-idle-call');

	// Initialize the library (all console debuggers enabled)
	Janus.init({
		debug: 'all',
		callback: function() {
			// Make sure the browser supports WebRTC
			if (!Janus.isWebrtcSupported()) {
				console.log('No WebRTC support...');
				return;
			}
			// Create session
			janus = new Janus({
				server: server,
				success: function() {
					// Attach to echo test plugin
					janus.attach({
						plugin: 'janus.plugin.sip',
						opaqueId: opaqueId,
						success: function(pluginHandle) {
							sipcall = pluginHandle;
							Janus.log(
								'Plugin attached! (' +
									sipcall.getPlugin() +
									', id=' +
									sipcall.getId() +
									')'
							);
							registerSip(sipData);
						},
						error: function(error) {
							Janus.error('  -- Error attaching plugin...', error);
						},
						consentDialog: function(on) {
							Janus.debug(
								'Consent dialog should be ' + (on ? 'on' : 'off') + ' now'
							);
							console.log(on);
							if (on) {
								console.log('blockUI dialog');
								// Darken screen and show hint
								// $.blockUI({
								// 	message: '<div><img src="images/up_arrow.png"/></div>',
								// 	css: {
								// 		border: 'none',
								// 		padding: '15px',
								// 		backgroundColor: 'transparent',
								// 		color: '#aaa',
								// 		top: '10px',
								// 		left: navigator.mozGetUserMedia ? '-100px' : '300px'
								// 	}
								// });
							} else {
								// Restore screen
								// $.unblockUI();
								
								console.log('restore blockUI dialog');
							}
						},
						mediaState: function(medium, on) {
							Janus.log(
								'Janus ' +
									(on ? 'started' : 'stopped') +
									' receiving our ' +
									medium
							);
						},
						webrtcState: function(on) {
							Janus.log(
								'Janus says our WebRTC PeerConnection is ' +
									(on ? 'up' : 'down') +
									' now'
							);
						},
						onmessage: function(msg, jsep) {
							Janus.debug(' ::: Got a message :::');
							Janus.debug(msg);
							jsepAnswer = jsep;
							// Any error?
							var error = msg['error'];
							if (error != null && error != undefined) {
								if (!registered) {
									$('#authuser').removeAttr('disabled');
									$('#password').removeAttr('disabled');
									$('#register')
										.removeAttr('disabled')
										.click(registerUsername);
								} else {
									// Reset status
									sipcall.hangup();
								}
								console.log(error)
								return;
							}
							var result = msg['result'];
							if (
								result !== null &&
								result !== undefined &&
								result['event'] !== undefined &&
								result['event'] !== null
							) {
								var event = result['event'];
								if (event === 'registration_failed') {
									Janus.warn(
										'Registration failed: ' +
											result['code'] +
											' ' +
											result['reason']
									);
									$('#authuser').removeAttr('disabled');
									$('#password').removeAttr('disabled');
									$('#register')
										.removeAttr('disabled')
										.click(registerUsername);
									console.log(result['code'] + ' ' + result['reason']);
									return;
								}
								if (event === 'registered') {
									Janus.log(
										'Successfully registered as ' + result['username'] + '!'
									);
									$('#you')
										.removeClass('hide')
										.show()
										.text("Registered as '" + result['username'] + "'");

									// TODO Enable buttons to call now
									if (!registered) {
										registered = true;
									}

									// Display Contacts and Call Logs page
									$('#siplogin').hide();
									$('#sipContacts')
										.removeClass('hide')
										.show();
									$('#sipCallLogs')
										.removeClass('hide')
										.show();

									$('#navbar')
										.removeClass('hide')
										.show();

									fetchData(result["username"]);
									callEvent = 'registered';
									// callEvent.next('registered');

								} else if (event === 'calling') {
									console.log(result);
									Janus.log('Waiting for the peer to answer...');
									callEvent = 'calling';
									// $('.call-status').html('calling mah');
									eleStatus.html('Calling');
									eleOutgoing.removeClass('d-none');
									eleIdle.addClass('d-none');
									eleIncoming.addClass('d-none');
									
									$('.time-table-call-status').html('Calling');
									$('.time-table-outgoing-call').removeClass('d-none');
									$('.time-table-idle-call').addClass('d-none');
									$('.time-table-incoming-call').addClass('d-none');
									
									// eleVoipHeader.html(result['displayname']);
									// eleVoipHeader.html('SG1797R');
									voipPanel.removeClass('d-none');

									// callEvent.next('calling');
									playRingtone(false);
								} else if (event === 'incomingcall') {
									playRingtone(true);
									console.log(result);
									eleEventListner.click();
									// eleStatus.html('Incoming call ' + result['displayname']);
									eleStatus.html('Incoming call');
									eleOutgoing.addClass('d-none');
									eleIncoming.removeClass('d-none');
									eleIdle.addClass('d-none');

									// eleVoipHeader.html(result['displayname']);
									eleVoipHeader.html(result['displayname']);
									voipPanel.removeClass('d-none');

									Janus.log('Incoming call from ' + result['username'] + '!');
									var doAudio = true, doVideo = false; 
									var offerlessInvite = false;
									if (jsep !== null && jsep !== undefined) {
										// What has been negotiated?
										doAudio = jsep.sdp.indexOf('m=audio ') > -1,
										doVideo = false; //jsep.sdp.indexOf('m=video ') > -1;
									} else {
										Janus.log(
											"This call doesn't contain an offer... we'll need to provide one ourselves"
										);
										offerlessInvite = true;
										// In case you want to offer video when reacting to an offerless call, set this to true
										doVideo = false;
									}
									// Any security offered? A missing "srtp" attribute means plain RTP
									var rtpType = '';
									var srtp = result['srtp'];
									if (srtp === 'sdes_optional')
										rtpType = ' (SDES-SRTP offered)';
									else if (srtp === 'sdes_mandatory')
										rtpType = ' (SDES-SRTP mandatory)';
									// Notify user
									var extra = '';
									if (offerlessInvite) extra = ' (no SDP offer provided)';
									
									
									//doAnswer
									// incoming = bootbox.dialog({
									// 	message:
									// 		'Incoming call from ' +
									// 		result['username'] +
									// 		'!' +
									// 		rtpType +
									// 		extra,
									// 	title: 'Incoming call',
									// 	closeButton: false,
									// 	buttons: {
									// 		success: {
									// 			label: 'Answer',
									// 			className: 'btn-success',
									// 			callback: function() {
									// 				incoming = null;
									// 				$('#peer')
									// 					.val(result['username'])
									// 					.attr('disabled', true);
									// 				// Notice that we can only answer if we got an offer: if this was
									// 				// an offerless call, we'll need to create an offer ourselves
									// 				var sipcallAction = offerlessInvite
									// 					? sipcall.createOffer
									// 					: sipcall.createAnswer;
									// 				sipcallAction({
									// 					jsep: jsep,
									// 					media: { audio: doAudio, video: false }, //doVideo },
									// 					success: function(jsep) {
									// 						Janus.debug(
									// 							'Got SDP ' +
									// 								jsep.type +
									// 								'! audio=' +
									// 								doAudio
									// 						);
									// 						Janus.debug(jsep);
									// 						var body = { request: 'accept' };
									// 						// Note: as with "call", you can add a "srtp" attribute to
									// 						// negotiate/mandate SDES support for this incoming call.
									// 						// The default behaviour is to automatically use it if
									// 						// the caller negotiated it, but you may choose to require
									// 						// SDES support by setting "srtp" to "sdes_mandatory", e.g.:
									// 						//		var body = { request: "accept", srtp: "sdes_mandatory" };
									// 						// This way you'll tell the plugin to accept the call, but ONLY
									// 						// if SDES is available, and you don't want plain RTP. If it
									// 						// is not available, you'll get an error (452) back. You can
									// 						// also specify the SRTP profile to negotiate by setting the
									// 						// "srtp_profile" property accordingly (the default if not
									// 						// set in the request is "AES_CM_128_HMAC_SHA1_80")
									// 						// Note 2: by default, the SIP plugin auto-answers incoming
									// 						// re-INVITEs, without involving the browser/client: this is
									// 						// for backwards compatibility with older Janus clients that
									// 						// may not be able to handle them. If you want to receive
									// 						// re-INVITES to handle them yourself, specify it here, e.g.:
									// 						//		body["autoaccept_reinvites"] = false;
									// 						sipcall.send({ message: body, jsep: jsep });
									// 						console.log(body);
									// 						console.log(jsep);
									// 					},
									// 					error: function(error) {
									// 						Janus.error('WebRTC error:', error);
									// 						bootbox.alert(
									// 							'WebRTC error... ' + JSON.stringify(error)
									// 						);
									// 						// Don't keep the caller waiting any longer, but use a 480 instead of the default 486 to clarify the cause
									// 						var body = { request: 'decline', code: 480 };
									// 						console.log(body);
									// 						sipcall.send({ message: body });
									// 					}
									// 				});
									// 			}
									// 		},
									// 		danger: {
									// 			label: 'Decline',
									// 			className: 'btn-danger',
									// 			callback: function() {
									// 				incoming = null;
									// 				var body = { request: 'decline' };
									// 				sipcall.send({ message: body });
									// 			}
									// 		}
									// 	}
									// });
								} else if (event === 'accepting') {
									// Response to an offerless INVITE, let's wait for an 'accepted'
								} else if (event === 'progress') {
									eleStatus.html('Progress');
									callEvent = 'progress';
									// callEvent.next('progress');
									stopRingtone();
									Janus.log(
										"There's early media from " +
											result['username'] +
											', waiting for the call!'
									);
									Janus.log(jsep);
									// Call can start already: handle the remote answer
									if (jsep !== null && jsep !== undefined) {
										sipcall.handleRemoteJsep({ jsep: jsep, error: doHangup });
									}
									toastr.info('Early media...');
								} else if (event === 'accepted') {
									stopRingtone();
									Janus.log(result['username'] + ' accepted the call!');
									Janus.log(jsep);
									eleStatus.html('Connected');
									eleOutgoing.removeClass('d-none');
									eleIncoming.addClass('d-none');
									
									$('.time-table-call-status').html('Connected');
									$('.time-table-outgoing-call').removeClass('d-none');
									$('.time-table-incoming-call').addClass('d-none');
									
									// Call can start, now: handle the remote answer
									if (jsep !== null && jsep !== undefined) {
										sipcall.handleRemoteJsep({ jsep: jsep, error: doHangup });
									}
									toastr.success('Call accepted!');
									// bootbox.hideAll();
									// bootbox.dialog({
									// 	title: 'Call',
									// 	message: 'Call connected...',
									// 	closeButton: false,
									// 	buttons: {
									// 		ok: {
									// 			label: 'Hang Up',
									// 			className: 'btn-danger',
									// 			callback: function() {
									// 				doHangup();
									// 			}
									// 		}
									// 	}
									// });
								} else if (event === 'updatingcall') {
									stopRingtone();
									// We got a re-INVITE: while we may prompt the user (e.g.,
									// to notify about media changes), to keep things simple
									// we just accept the update and send an answer right away
									Janus.log('Got re-INVITE');
									var doAudio = jsep.sdp.indexOf('m=audio ') > -1,
									doVideo = false; //jsep.sdp.indexOf('m=video ') > -1;
									sipcall.createAnswer({
										jsep: jsep,
										media: { audio: doAudio, video: false }, //doVideo },
										success: function(jsep) {
											Janus.debug(
												'Got SDP ' +
													jsep.type +
													'! audio=' +
													doAudio
											);
											Janus.debug(jsep);
											var body = { request: 'update' };
											console.log(body);
											console.log(jsep);
											sipcall.send({ message: body, jsep: jsep });
										},
										error: function(error) {
											Janus.error('WebRTC error:', error);
										}
									});
								} else if (event === 'hangup') {
									callEvent = 'hangup';
									// callEvent.next('hangup');
									// eleStatus.html('Hangup, ' + result['reason']);
									eleStatus.html('Call ended');
									eleIncoming.addClass('d-none');
									eleOutgoing.addClass('d-none');
									eleIdle.removeClass('d-none');

									// eleTimeTableStatus.html('Call ended');
									// var eleTimeTableStatus = $('.time-table-call-status');
									// var eleTimeTableIncoming = $('.time-table-incoming-call');
									// var eleTimeTableOutgoing = $('.time-table-outgoing-call');
									// var eleTimeTableIdle = $('.time-table-idle-call');

									$('.time-table-call-status').html('Call ended');
									$('.time-table-incoming-call').addClass('d-none');
									$('.time-table-outgoing-call').addClass('d-none');
									$('.time-table-idle-call').removeClass('d-none');
									$('.close-voip-panel').prop('disabled', false);

									stopRingtone();
									if (incoming != null) {
										incoming.modal('hide');
										incoming = null;
									}
									Janus.log(
										'Call hung up (' +
											result['code'] +
											' ' +
											result['reason'] +
											')!'
									);
									console.log(result);
									console.log(eleStatus);
									// Reset status
									sipcall.hangup();
								}
							}
						},
						onlocalstream: function(stream) {
							Janus.debug(' ::: Got a local stream :::');
							Janus.debug(stream);
							Janus.attachMediaStream($('#myvideo').get(0), stream);
							$('#myvideo').get(0).muted = 'muted';
						},
						onremotestream: function(stream) {
							Janus.debug(' ::: Got a remote stream :::');
							Janus.debug(stream);
							Janus.attachMediaStream($('#remotevideo').get(0), stream);
						},
						oncleanup: function() {
							Janus.log(' ::: Got a cleanup notification :::');
							refreshCallLogs();
						}
					});
				},
				error: function(error) {
					Janus.error(error);
					// var dialog = bootbox.dialog({
					// 	title: 'Connecting to Server',
					// 	message: '<p>Error in connection.</p>',
					// 	buttons: {
					// 		ok: {
					// 			label: 'Retry',
					// 			className: 'btn-info',
					// 			callback: function() {
					// 				window.location.reload();
					// 			}
					// 		},
					// 		cancel: {
					// 			label: 'Cancel',
					// 			className: 'btn-danger'
					// 		}
					// 	}
					// });
				},
				destroyed: function() {
					window.location.reload();
				}
			});
		}
	});
}

function checkEnter(field, event) {
	var theCode = event.keyCode
		? event.keyCode
		: event.which
		? event.which
		: event.charCode;
	if (theCode == 13) {
		if (
			//field.id == 'server' ||
			//field.id == 'username' ||
			field.id == 'password' ||
			//field.id == 'displayname' ||
			field.id == 'authuser'
		)
			registerUsername();
		return false;
	} else {
		return true;
	}
}

function registerUsername() {
	validated = false;
	selectedApproach = 'secret'; // set to "secret" as default for demo

	// Try a registration
	$('#authuser').attr('disabled', true);
	$('#password').attr('disabled', true);
	$('#register')
		.attr('disabled', true)
		.unbind('click');

	// Let's see if the user provided a server address
	// 		NOTE WELL! Even though the attribute we set in the request is called "proxy",
	//		this is actually the _registrar_. If you want to set an outbound proxy (for this
	//		REGISTER request and for all INVITEs that will follow), you'll need to set the
	//		"outbound_proxy" property in the request instead. The two are of course not
	//		mutually exclusive. If you set neither, the domain part of the user identity
	//		will be used as the target of the REGISTER request the plugin might send.

	var authuser = $('#authuser').val();
	sipIdentity = 'sip:' + authuser + host;

	if (authuser == '') {
		// bootbox.alert('Please enter username');
		$('#authuser').removeAttr('disabled');
		$('#password').removeAttr('disabled');
		$('#register')
			.removeAttr('disabled')
			.click(registerUsername);
		return;
	}
	if (
		sipIdentity === '' ||
		sipIdentity.indexOf('sip:') != 0 ||
		sipIdentity.indexOf('@') < 0
	) {
		// bootbox.alert(
		// 	'Please insert a valid SIP identity address (e.g., sip:goofy@example.com)'
		// );
		$('#authuser').removeAttr('disabled');
		$('#password').removeAttr('disabled');
		$('#register')
			.removeAttr('disabled')
			.click(registerUsername);
		return;
	}
	var password = $('#password').val();
	if (password === '') {
		// bootbox.alert('Please enter password');
		$('#authuser').removeAttr('disabled');
		$('#password').removeAttr('disabled');
		$('#register')
			.removeAttr('disabled')
			.click(registerUsername);
		return;
	}
	var register = {
		request: 'register',
		username: sipIdentity, 
		force_udp: true
	};

	if (selectedApproach === 'secret') {
		// Use the plain secret
		register['secret'] = password;
	} 
		sipcall.send({ message: register });
}

function registerSip(sipData) {
	console.log(sipData);
	// var register = {
	// 	request: 'register',
	// 	username: sipIdentity, 
	// 	force_udp: true
	// };

	// if (selectedApproach === 'secret') {
	// 	// Use the plain secret
	// 	register['secret'] = password;
	// } 
	sipcall.send({ message: sipData });
}

function doCall(peerSipIdentity) {
	
	// Call someone
	var username = peerSipIdentity;
	if (username === '') {
		console.log(
			'Please insert a valid SIP address (e.g., sip:pluto@example.com)'
		);
		return;
	}
	if (username.indexOf('sip:') != 0 || username.indexOf('@') < 0) {
		console.log(
			'Please insert a valid SIP address (e.g., sip:pluto@example.com)'
		);
		return;
	}
	// Call this URI
	doVideo = false; // video is not supported
	Janus.log('peerSipIdentity: ' + username);

	// bootbox.dialog({
	// 	title: 'Call',
	// 	message: 'Calling ' + peerSipIdentity,
	// 	closeButton: false,
	// 	buttons: {
	// 		ok: {
	// 			label: 'Hang Up',
	// 			className: 'btn-danger',
	// 			callback: function() {
	// 				doHangup();
	// 			}
	// 		}
	// 	}
	// });
	console.log('Calling ' + username);
	console.log(sipcall);
		  
	sipcall.createOffer({
		media: {
			audioSend: true,
			audioRecv: true, // We DO want audio
			videoSend: false, //doVideo,
			videoRecv: false //doVideo // We MAY want video
		},
		success: function(jsep) {
			Janus.debug('Got SDP!');
			Janus.debug(jsep);

			// By default, you only pass the SIP URI to call as an
			// argument to a "call" request. Should you want the
			// SIP stack to add some custom headers to the INVITE,
			// you can do so by adding an additional "headers" object,
			// containing each of the headers as key-value, e.g.:
			//		var body = { request: "call", uri: $('#peer').val(),
			//			headers: {
			//				"My-Header": "value",
			//				"AnotherHeader": "another string"
			//			}
			//		};

			var body = { request: 'call', uri: peerSipIdentity };

			// Note: you can also ask the plugin to negotiate SDES-SRTP, instead of the
			// default plain RTP, by adding a "srtp" attribute to the request. Valid
			// values are "sdes_optional" and "sdes_mandatory", e.g.:
			//		var body = { request: "call", uri: $('#peer').val(), srtp: "sdes_optional" };
			// "sdes_optional" will negotiate RTP/AVP and add a crypto line,
			// "sdes_mandatory" will set the protocol to RTP/SAVP instead.
			// Just beware that some endpoints will NOT accept an INVITE
			// with a crypto line in it if the protocol is not RTP/SAVP,
			// so if you want SDES use "sdes_optional" with care.
			// Note 2: by default, the SIP plugin auto-answers incoming
			// re-INVITEs, without involving the browser/client: this is
			// for backwards compatibility with older Janus clients that
			// may not be able to handle them. If you want to receive
			// re-INVITES to handle them yourself, specify it here, e.g.:
			//		body["autoaccept_reinvites"] = false;
			sipcall.send({ message: body, jsep: jsep });
		},
		error: function(error) {
			// bootbox.hideAll();
			Janus.error('WebRTC error...', error);
			// bootbox.alert('WebRTC error... ' + JSON.stringify(error));
		}
	});
}

function doHangup() {
	// Hangup a call
	var hangup = { request: 'hangup' };
	sipcall.send({ message: hangup });
	sipcall.hangup();
}

// fetch contacts list and call logs
var contacts = {};
let isRefreshingContacts = false;
function fetchData(username) {
	console.log('fetch contacts');
	if (isRefreshingContacts) return;
	isRefreshingContacts = true;

	var contactList = getContactList();
	contactList.then(function(data) {
		console.log(data);
		$('#tblContactList')
		.find('tr:gt(0)')
		.remove();

		$.each(data, function(i, item) {
			contacts[item.dialNumber] = item.name;

			var contactSipIdentity = 'sip:' + item.dialNumber + host;
			if (username != item.dialNumber) {
				// should not display login user in contacts
				var rows =
					"<tr><td width='24px'><i class='fa fa-user fa-fw'></i></td>" +
					'<td>' +
					item.name +
					' (' +
					item.dialNumber +
					')</td>' +
					"<td width='24px'><button style='background:none;border:none' autocomplete='off' id='call-" +
					item.dialNumber +
					"' onclick='doCall(\"" +
					contactSipIdentity +
					"\")'><i class='fa fa-phone fa-fw call-icon' id=" +
					contactSipIdentity +
					'></i></button></td></tr>;';
					console.log(contactSipIdentity);
				$('#tblContactList').append(rows);
			} else {
				// set the account name for display and set the sip address
				mySipIdentity = contactSipIdentity;
				myDialNumber = item.dialNumber;
				loginUser = item.name + ' (' + item.dialNumber + ')';
				$('#loginName').html(loginUser); // show logged in user account
			}
		}); //End of foreach Loop
		isRefreshingContacts = false;
		refreshCallLogs();
	});
}

let isRefreshingCallLogs = false;
// always fetch call logs after contacts, otherwise unable to match dialNumber
function refreshCallLogs() {
	// refresh after every call
	console.log('refresh call logs');
	if (isRefreshingCallLogs) return;
	isRefreshingCallLogs = true;

	var callLogs = getCallLogs();
	callLogs.then(function(data) {
		// console.log(data);
		$('#tblCallLogs')
		.find('tr:gt(0)')
		.remove();

		// sort logs, latest first
		data.sort(function (a, b) {
			if (a.startTime > b.startTime){
				return -1;
			} else if (a.startTime < b.startTime){
				return 1;
			} else {
					return 0;
			}
		});

		$.each(data, function(i, item) {
			// filter calls that do not belong to logged in user
			if (item.caller == myDialNumber || item.callee == myDialNumber) {
				var isMissedCall = item.statusCode == '487' ? true : false;
				var logClass = isMissedCall ? 'missed-call' : 'normal-call';

				let callType = item.caller == myDialNumber ? "Outgoing Call" : "Incoming Call";
				callType = isMissedCall ? "Missed Call" : callType;

				let peerId = (item.caller == myDialNumber) ? item.callee : item.caller;
				var contactSipIdentity = 'sip:' + peerId + host;

				var rows =
					"<tr class='" +
					logClass +
					"'><td><button style='background:none;border:none' autocomplete='off' id='call-" +
					peerId +
					"' onclick='doCall(\"" +
					contactSipIdentity +
					"\")'><i class='fa fa-phone fa-fw' id=" +
					contactSipIdentity +
					'></i></button></td>' +
					'<td>' +
					(contacts[peerId] ? contacts[peerId]: peerId) +
					'</td>' +
					'<td>' +
					moment(item.startTime).format('DD-MM-YYYY h:mm:ss a') +
					'</td>' +
					'<td>' + 
					callType +
					'</td>' +
					'<td>' +
					moment(item.endTime).from(moment(item.startTime), true) +
					'</td></tr>;';
				$('#tblCallLogs').append(rows);
			}
		}); //End of foreach Loop
		isRefreshingCallLogs = false;
	}, function errorCallback(response) {
		console.error('Error ', error);
		isRefreshingCallLogs = false;
	});
	// callLogs.error(function(error) {
	// 	console.error('Error ', error);
	// 	isRefreshingCallLogs = false;
	// });
}

function doAnswer(){
	console.log(jsepAnswer);
	var doAudio = true;
	doAudio = jsepAnswer.sdp.indexOf('m=audio') > -1;

	sipcall.createAnswer({
		jsep: jsepAnswer,
		media: { audio: doAudio, video: false }, //doVideo },
		success: function(jsep) {
			Janus.debug(
				'Got SDP ' +
					jsep.type +
					'! audio=' +
					doAudio
			);
			Janus.debug(jsep);
			var body = { request: 'accept' };
			sipcall.send({ message: body, jsep: jsep });
			console.log(body);
			console.log(jsep);
		},
		error: function(error) {
			Janus.error('WebRTC error:', error);
			console.log(
				'WebRTC error... ' + JSON.stringify(error)
			);
			// Don't keep the caller waiting any longer, but use a 480 instead of the default 486 to clarify the cause
			var body = { request: 'decline', code: 480 };
			console.log(body);
			sipcall.send({ message: body });
		}
	});
}

function doDecline() {
	
	// Janus.error('WebRTC error:', error);
	// bootbox.alert(
	// 	'WebRTC error... ' + JSON.stringify(error)
	// );
	// Don't keep the caller waiting any longer, but use a 480 instead of the default 486 to clarify the cause
	var body = { request: 'decline', code: 480 };
	console.log(body);
	sipcall.send({ message: body });
}