function getCallLogs() {
	return $.ajax({
		// url: 'https://ec2-18-136-242-141.ap-southeast-1.compute.amazonaws.com/calllogs/atc?daysoflogs=30',
		url: 'https://10.12.0.105/calllogs/atc?daysoflogs=30',
		// url: 'https://ngbms-sg.com:8080/voip/logs/',
	});
}

function getContactList() {
	return $.ajax({
		// url: 'https://ec2-18-136-242-141.ap-southeast-1.compute.amazonaws.com/contacts',
		url: 'https://10.12.0.105/contacts',
		// url: 'https://ngbms-sg.com:8080/voip/contacts/',
		error: function(error) {
			console.error('Error ', error);
		}
	});
}
