
    function TripCompletionCalc() {
      this.routes = new Map();
      this.stops = new Map();
    }
    
    TripCompletionCalc.prototype.addRoute = function(route) {
      var routeId = route.route[0];

      if (!this.routes.has(routeId)) {
        this.indexRoute(route);
        this.routes.set(routeId, route);
		
        route.direction.forEach(x => {
          x.stops.forEach(y => {
            let stopId = y.stopCode;
            this.stops.set(stopId, y);
          });
        });
      }
    }

    TripCompletionCalc.prototype.indexRoute = function(route) {
      route.direction.forEach(x => {
        x.stopIdx = [];

        x.stops.forEach(y => {
          let stopId = y.stopCode;
          x.stopIdx.push(stopId);
        });
      });
    }

    TripCompletionCalc.prototype.hasStopId = function(direction, stopId) {
      return direction.stopIdx.includes(stopId);
    }

    TripCompletionCalc.prototype.findDirection = function(route, prevStopId, nextStopId) {
      var result = undefined;

      route.direction.forEach(x => {
        if (this.hasStopId(x, prevStopId) && this.hasStopId(x, nextStopId)) {
          result = x;
        }
      });

      return result;
    }

    TripCompletionCalc.prototype.isFirstStop = function(dir, stopId) {
      return dir.stopIdx.indexOf(stopId) == 0;
    }

    TripCompletionCalc.prototype.isLastStop = function(dir, stopId) {
      return dir.stopIdx.indexOf(stopId) == dir.stopIdx.length - 1;
    }
    
    TripCompletionCalc.prototype.calc = function(routeId, geoloc) {
      var route = this.routes.get(routeId);
      
      var dir = this.findDirection(route, geoloc.prevStopId, geoloc.nextStopId);

      var result;

      if (this.isFirstStop(dir, geoloc.nextStopId)) {
        result = 0;
      } else if (this.isLastStop(dir, geoloc.prevStopId)) {
        result = 100;
      } else {
        result = dir.stopIdx.indexOf(geoloc.prevStopId) / dir.stopIdx.length + geoloc.distToPrev / (geoloc.distToPrev + geoloc.distToNext) / dir.stopIdx.length;
      }

      return result;
    }
    
    TripCompletionCalc.prototype.findDirectionId = function(routeId, geoloc) {
      var route = this.routes.get(routeId);
      var dir = this.findDirection(route, geoloc.prevStopId, geoloc.nextStopId);

      return dir.value;
    }

    TripCompletionCalc.prototype.findStop = function(stopId) {
      return this.stops.get(stopId);
    }

    var tcc = new TripCompletionCalc();